export default ($) => ({
   	accordion(){
	    $('.acc-head').click(function(j) {
			var dropDown = $(this).closest('.box-acc').find('.acc-body');
			$(this).closest('.acc').find('.acc-body').not(dropDown).slideUp();
			
			if ($(this).hasClass('active')) {
			  $(this).removeClass('active');
			} else {
			  $(this).closest('.accordion').find('.acc-head.active').removeClass('active');
			  $(this).addClass('active');
			}
			
			dropDown.stop(false, true).slideToggle();
			j.preventDefault();
		});
	},
	init(){
        this.accordion()
    }
})