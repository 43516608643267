import * as $ from 'jquery';
import accordion  from './libs/accordion.js'
import popup  from './libs/popup.js';

window.$ = $



$(document).ready(() => {
	accordion($).accordion()
 	const header = $("header"),
        fnc = {},
        ww = $(window).width();
    fnc.runfnc = function () {
        var topmin = 0;

        if($(window).scrollTop() > topmin){
            if(!header.hasClass("float")){
                header.addClass("float");
            }
        }else{
            if(header.hasClass("float")){
                header.removeClass("float");
            }
        }
    } 
    $(window).scroll(function () {
        fnc.runfnc();
    })
    fnc.runfnc();

    $('header .togglemenu-mobile').on('click',function(){
        $(this).parents('.right-header').find('nav').slideToggle();
        $(this).toggleClass('active');
    });

    $('header .right-header .dropdown').on('click',function(){
        $(this).find('ul').slideToggle();
    })
    // hasTouch()

    $('.popupClick').on('click', function(e){
		e.preventDefault();
		popup($).openpopup('#pop-resource')
	});
    popup($).init()

})


// function hasTouch() {
//     return 'ontouchstart' in document.documentElement
//            || navigator.maxTouchPoints > 0
//            || navigator.msMaxTouchPoints > 0;
// }

// if (hasTouch()) { // remove all :hover stylesheets
//     try { // prevent exception on browsers not supporting DOM styleSheets properly
//         for (var si in document.styleSheets) {
//             var styleSheet = document.styleSheets[si];
//             if (!styleSheet.rules) continue;

//             for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
//                 if (!styleSheet.rules[ri].selectorText) continue;

//                 if (styleSheet.rules[ri].selectorText.match(':hover')) {
//                     styleSheet.deleteRule(ri);
//                 }
//             }
//         }
//     } catch (ex) {}
// }





